<template>
  <!-- <header class="galaxy-header"> -->
    <!-- <router-link class="galaxy-header-logo" to="/"> -->
<!--      <svg-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--        width="119.717"-->
<!--        height="59.828"-->
<!--        viewBox="0 0 119.717 59.828"-->
<!--      >-->
<!--        <g-->
<!--          id="갤럭시유니버스_로고"-->
<!--          data-name="갤럭시유니버스 로고"-->
<!--          transform="translate(-51.297 -47)"-->
<!--        >-->
<!--          <path-->
<!--            id="패스_419"-->
<!--            data-name="패스 419"-->
<!--            d="M158.316,39.751l.025,5.758,0,.589-18.07.081.048,10.337,12.173-.056-.011-2.864-5.894.025-2.994.014-.022-4.607,2.994-.014,11.789-.053.036,7.474,0,.589.028,5.757-5.9.025-15.33.073-4.739-4.585-.064-13.817,4.7-4.627Z"-->
<!--            transform="translate(-17.825 26.245)"-->
<!--            fill="#fff"-->
<!--          />-->
<!--          <path-->
<!--            id="패스_425"-->
<!--            data-name="패스 425"-->
<!--            d="M509.2,38.266l8.049-.036.073,16.441,9.2-.042-.076-16.441,8.041-.036.092,19.538L530,61.156l-16.091.076L509.291,57.8Z"-->
<!--            transform="translate(-363.561 27.081)"-->
<!--            fill="#fff"-->
<!--          />-->
<!--          <path-->
<!--            id="패스_432"-->
<!--            data-name="패스 432"-->
<!--            d="M25.838,0,0,15.077.138,44.991,26.113,59.829,51.95,44.752l-.138-29.914ZM46.076,29.845c0,.06,0,.12,0,.181,0,.093,0,.186-.006.279,0,.014,0,.028,0,.042a10.069,10.069,0,0,1-1.811,5.306,9.3,9.3,0,0,1-1.736,1.865q-.248.2-.511.386a8.66,8.66,0,0,1-2.976,1.335,9.137,9.137,0,0,1-2.085.242l-.824,0a8.71,8.71,0,0,1-3.441-.665,9.775,9.775,0,0,1-2.958-1.657,10.935,10.935,0,0,1-1.267-1.221,13.291,13.291,0,0,1-1.138-1.489,18.243,18.243,0,0,1-1.332-2.413,18.154,18.154,0,0,1-1.309,2.425,13.345,13.345,0,0,1-1.124,1.5q-.2.227-.4.44a9.875,9.875,0,0,1-3.794,2.476,8.615,8.615,0,0,1-1.665.52,8.946,8.946,0,0,1-1.769.177l-.824,0a9.09,9.09,0,0,1-2.087-.223,8.652,8.652,0,0,1-2.988-1.307q-.265-.181-.515-.381a9.283,9.283,0,0,1-1.753-1.849,10.063,10.063,0,0,1-1.859-5.288c0-.014,0-.028,0-.042,0-.093-.006-.185-.008-.278,0-.061-.005-.12-.006-.181v-.044c0-.061,0-.12,0-.181,0-.093,0-.186.006-.279,0-.014,0-.028,0-.042a9.865,9.865,0,0,1,.863-3.632,9.468,9.468,0,0,1,3.195-3.924,8.654,8.654,0,0,1,2.329-1.155,9.01,9.01,0,0,1,2.733-.422l.824,0a8.932,8.932,0,0,1,1.771.161,8.6,8.6,0,0,1,1.67.5,9.775,9.775,0,0,1,2.958,1.657,10.633,10.633,0,0,1,.86.784q.207.211.408.436a13.291,13.291,0,0,1,1.138,1.489,18.244,18.244,0,0,1,1.332,2.413,18.154,18.154,0,0,1,1.309-2.425,13.345,13.345,0,0,1,1.124-1.5,10.887,10.887,0,0,1,1.256-1.232A9.766,9.766,0,0,1,32.6,20.951a8.706,8.706,0,0,1,3.435-.7l.824,0a9.017,9.017,0,0,1,2.736.4,8.661,8.661,0,0,1,2.339,1.134,9.462,9.462,0,0,1,3.231,3.894,9.866,9.866,0,0,1,.9,3.624c0,.014,0,.028,0,.042,0,.093.006.185.008.278,0,.06.005.12.006.181v.045"-->
<!--            transform="translate(51.297 46.999)"-->
<!--            fill="#fff"-->
<!--          />-->
<!--        </g>-->
<!--      </svg>-->
      <!-- <img :src="logoUrl" alt="mirinae"> -->
    <!-- </router-link> -->
    <!-- <ul class="galaxy-header-list"> -->
      <!-- 첫페이지 진입시 on클래스를 넣어 해당 영역 활성화 되게 수정 -->
      <!-- <li class="galaxy-header-item">
        <router-link to="/firstlight">현실의세계</router-link>
      </li>
      <li class="galaxy-header-item">
        <router-link to="/rainbowshadow">상상의세계</router-link>
      </li>
      <li class="galaxy-header-item" :class="{ on: $route.name === 'Home' }">
         <router-link to="/avadream">꿈의세계</router-link>
      </li>
    </ul> -->
    <!-- <MenuLayer /> -->
  <!-- </header> -->
</template>

<script>
import MenuLayer from "@/components/header/MenuLayer";
import {Navigation} from "swiper";
import { reactive, toRefs } from "vue";
export default {
  name: "Header",
  components: { MenuLayer },
  setup(){
    const state = reactive({
      logoUrl:require("@/assets/image/logo.png")
    })
    return {
      ...toRefs(state),
    };
  }
};
</script>

<style lang="scss" src="@/assets/style/common/header.scss" />
